<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>学术管理</el-breadcrumb-item>
      <el-breadcrumb-item>词库管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 字库信息筛选 -->
    <el-card style="margin-top: 15px" v-if="this.queryForm.matchType !== '17'">
      <el-row class="title_row">Í
        <el-col>
          <span class="title_class">条件筛选</span>
        </el-col>
      </el-row>
      <el-form ref="queryFormRef" :model="queryForm" label-width="80px">
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="单词" prop="cWord">
              <el-input
                v-model="queryForm.cWord"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="定义" prop="cWord">
              <el-input
                v-model="queryForm.cWord"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="例句" prop="cWord">
              <el-input
                v-model="queryForm.cWord"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态" prop="cType">
              <el-select v-model="queryForm.cType">
                <el-option label="上架" :value="1"></el-option>
                <el-option label="下架" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="标签" prop="cType">
              <el-cascader :props="props"></el-cascader>
              <!-- <el-select v-model="queryForm.cType">
                <el-option
                  v-for="item in dict_type"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="二级标签" prop="cType">
              <el-select v-model="queryForm.cType">
                <el-option
                  v-for="item in dict_type"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getChineseWordList('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 词库列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="5">
          <span class="title-class title_class">词库列表</span>
        </el-col>
        <el-col :span="19" style="text-align: right">
          <el-button
            type="primary"
            plain
            @click="importChineseWordExcelDialogVisible = true"
            icon="el-icon-upload2"
            >批量导入</el-button
          >
          <el-button
            type="primary"
            plain
            @click="downloadInfoList"
            icon="el-icon-download"
            >批量导出</el-button
          >
          <el-button type="primary">新增</el-button>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="word" prop="serialNumber">
          <template slot-scope="scope">
            <div class="audio-play">
              <span>{{ scope.row.serialNumber }}</span>
              <i class="el-icon-video-play"></i>
              <!-- el-icon-video-pause -->
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Part of speech" prop="cword"></el-table-column>
        <el-table-column label="Definition" prop="pinyin"></el-table-column>
        <el-table-column label="Sentence" prop="clevel"></el-table-column>
        <el-table-column label="Voice" prop="clevel">
          <template>
            <div class="audio-play">
              <span>voice</span>
              <i class="el-icon-video-play"></i>
              <!-- el-icon-video-pause -->
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column
          label="字体类型"
          prop="ctype"
          :formatter="typeFormatter"
        ></el-table-column>
        <el-table-column
          label="主题"
          prop="ctopic"
          :formatter="ctopicFormatter"
        ></el-table-column>
        <el-table-column
          label="所属产品"
          prop="productType"
          :formatter="productTypeFormatter"
        ></el-table-column> -->
        <el-table-column label="操作" width="140px">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="editDistrictMessage(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              style="color: #f56c6c"
              @click="deleteDialog(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 汉字详情弹窗 -->
    <el-dialog
      title="汉字详情"
      :visible.sync="editChineseCharactersDialogVisible"
      width="60%"
      @close="editChineseCharactersDialogClosed"
    >
      <el-card>
        <el-row class="title_row">
          <el-col>
            <span>基础信息</span>
          </el-col>
        </el-row>
        <el-form
          label-width="110px"
          :model="editChineseCharactersForm"
          ref="editChineseCharactersFormRef"
          :inline="true"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="简体:" prop="simpleUrl">
                <el-upload
                  class="wordImg"
                  :action="this.$http.defaults.baseURL + '/system/upLoadFile'"
                  :show-file-list="false"
                  :on-success="handleUploadSimpleImgSuccess"
                  :before-upload="beforeImgUpload"
                  :data="{
                    upLoadPicUrl: uploadImgUrl,
                  }"
                >
                  <img
                    v-if="editChineseCharactersForm.simpleUrl"
                    :src="editChineseCharactersForm.simpleUrl"
                    class="wordImg"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="篆书:" prop="sealScript">
                <el-upload
                  class="wordImg"
                  :action="this.$http.defaults.baseURL + '/system/upLoadFile'"
                  :show-file-list="false"
                  :on-success="handleUploadSealScriptImgSuccess"
                  :before-upload="beforeImgUpload"
                  :data="{
                    upLoadPicUrl: uploadImgUrl,
                  }"
                >
                  <img
                    v-if="editChineseCharactersForm.sealScript"
                    :src="editChineseCharactersForm.sealScript"
                    class="wordImg"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="隶书:" prop="officialScript">
                <el-upload
                  class="wordImg"
                  :action="this.$http.defaults.baseURL + '/system/upLoadFile'"
                  :show-file-list="false"
                  :on-success="handleUploadOfficialScriptImgSuccess"
                  :before-upload="beforeImgUpload"
                  :data="{
                    upLoadPicUrl: uploadImgUrl,
                  }"
                >
                  <img
                    v-if="editChineseCharactersForm.officialScript"
                    :src="editChineseCharactersForm.officialScript"
                    class="wordImg"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="楷书:" prop="regularScript">
                <el-upload
                  class="wordImg"
                  :action="this.$http.defaults.baseURL + '/system/upLoadFile'"
                  :show-file-list="false"
                  :on-success="handleUploadRegularScriptImgSuccess"
                  :before-upload="beforeImgUpload"
                  :data="{
                    upLoadPicUrl: uploadImgUrl,
                  }"
                >
                  <img
                    v-if="editChineseCharactersForm.regularScript"
                    :src="editChineseCharactersForm.regularScript"
                    class="wordImg"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="汉字构型:" prop="wordUrl">
                <el-upload
                  class="wordImg"
                  :action="this.$http.defaults.baseURL + '/system/upLoadFile'"
                  :show-file-list="false"
                  :on-success="handleUploadWordUrlImgSuccess"
                  :before-upload="beforeImgUpload"
                  :data="{
                    upLoadPicUrl: uploadImgUrl,
                  }"
                >
                  <img
                    v-if="editChineseCharactersForm.wordUrl"
                    :src="editChineseCharactersForm.wordUrl"
                    class="wordImg"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item label="拼音:" prop="pinyin">
              <el-input
                v-model="editChineseCharactersForm.pinyin"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="音频:">
              <el-upload
                :action="uploadAudioResults.uploadUrl"
                ref="uploadAudioResultsRef"
                :data="{
                  cLevel: '',
                  productType: '',
                }"
                :headers="uploadAudioResults.headerObj"
                :on-progress="importChineseWordExcelProgress"
                :on-success="uploadAudioResultsSuccess"
                :on-error="importChineseWordExcelError"
                :on-exceed="uploadAudioResultsExceed"
                :before-upload="uploadAudioResultsBeforeUpload"
                :limit="1"
                :on-change="onChangeLoad"
                :auto-upload="false"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >上传</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  style="margin-left: 10px"
                  v-if="editChineseCharactersForm.sound"
                  @click="downLoadAudio(editChineseCharactersForm.sound)"
                  >下载</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  v-if="editChineseCharactersForm.sound"
                  @click="playAudio(editChineseCharactersForm.sound)"
                  >播放</el-button
                >
              </el-upload>
            </el-form-item>
          </el-row>
          <el-form-item label="序列号:" prop="serialNumber">
            <el-input
              v-model="editChineseCharactersForm.serialNumber"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="中文:" prop="cword">
            <el-input
              v-model="editChineseCharactersForm.cword"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="组别:" prop="clevel">
            <el-select
              placeholder="请选择"
              style="width: 100%"
              v-model="editChineseCharactersForm.clevel"
              clearable
            >
              <el-option
                v-for="item in p_c_level"
                :key="item.dictCode"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="字体类型:" prop="ctype">
            <el-select
              placeholder="请选择"
              style="width: 100%"
              v-model="editChineseCharactersForm.ctype"
              clearable
            >
              <el-option
                v-for="item in dict_type"
                :key="item.dictCode"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option> </el-select></el-form-item
          ><el-form-item label="主题:" prop="ctopic">
            <el-select
              multiple
              placeholder="请选择"
              style="width: 100%"
              v-model="editChineseCharactersForm.ctopicList"
              @change="ctopicListClicked"
              clearable
            >
              <el-option
                v-for="(item, index) in dict_topic"
                :key="index"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-card>
      <el-tabs
        type="border-card"
        style="margin-top: 15px"
        @tab-click="clickLanguage()"
      >
        <el-tab-pane
          v-for="(item1, index) in editChineseCharactersForm.speechVo"
          :key="index"
          :label="labelFormatter(item1.languageType)"
          :name="index + ''"
        >
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-circle-plus-outline"
            @click="showAddPartOfSpeechDialog(item1.languageType)"
            >新增</el-button
          >
          <el-card
            v-for="(item2, index) in item1.part"
            :key="index"
            style="margin-top: 10px"
          >
            <el-row class="title_row">
              <el-col>
                <span>{{ wordNatureFormatter(item2) }}</span>
                <el-button
                  style="margin-left: 15px"
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  @click="deletePartOfSpeech(item1.part, index)"
                ></el-button>
              </el-col>
            </el-row>
            <el-form label-width="110px" :model="item2">
              <!-- 意思 -->
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    :label="partOfSpeechLabelFormatter(item2.languageType, 0)"
                    prop="meaning"
                  >
                    <el-input
                      v-model="item2.meaning"
                      placeholder="请输入"
                      type="textarea"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    :label="partOfSpeechLabelFormatter(item2.languageType, 1)"
                    prop="meaningUrl"
                  >
                    <el-upload
                      :action="uploadAudioResults.uploadUrl"
                      ref="uploadAudioResultsRef"
                      :data="{
                        path: '',
                        filenamed: '',
                      }"
                      :headers="uploadAudioResults.headerObj"
                      :on-progress="importChineseWordExcelProgress"
                      :on-success="uploadAudioResultsSuccess"
                      :on-error="importChineseWordExcelError"
                      :on-exceed="uploadAudioResultsExceed"
                      :before-upload="uploadAudioResultsBeforeUpload"
                      :limit="1"
                      :on-change="onChangeLoad"
                      :auto-upload="false"
                    >
                      <el-button slot="trigger" size="small" type="primary"
                        >上传</el-button
                      >
                      <el-button
                        size="small"
                        type="primary"
                        style="margin-left: 10px"
                        v-if="item2.meaningUrl"
                        @click="downLoadAudio(item2.meaningUrl)"
                        >下载</el-button
                      >
                      <el-button
                        size="small"
                        type="primary"
                        v-if="item2.meaningUrl"
                        @click="playAudio(item2.meaningUrl)"
                        >播放</el-button
                      >
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 例句 -->
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    :label="partOfSpeechLabelFormatter(item2.languageType, 2)"
                    prop="sentence"
                  >
                    <el-input
                      v-model="item2.sentence"
                      placeholder="请输入"
                      type="textarea"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    :label="partOfSpeechLabelFormatter(item2.languageType, 3)"
                    prop="sentenceUrl"
                  >
                    <el-upload
                      :action="uploadAudioResults.uploadUrl"
                      ref="uploadAudioResultsRef"
                      :data="{
                        path: '',
                        filenamed: '',
                      }"
                      :headers="uploadAudioResults.headerObj"
                      :on-progress="importChineseWordExcelProgress"
                      :on-success="uploadAudioResultsSuccess"
                      :on-error="importChineseWordExcelError"
                      :on-exceed="uploadAudioResultsExceed"
                      :before-upload="uploadAudioResultsBeforeUpload"
                      :limit="1"
                      :on-change="onChangeLoad"
                      :auto-upload="false"
                    >
                      <el-button slot="trigger" size="small" type="primary"
                        >上传</el-button
                      >
                      <el-button
                        size="small"
                        type="primary"
                        style="margin-left: 10px"
                        v-if="item2.sentenceUrl"
                        @click="downLoadAudio(item2.sentenceUrl)"
                        >下载</el-button
                      >
                      <el-button
                        size="small"
                        type="primary"
                        v-if="item2.sentenceUrl"
                        @click="playAudio(item2.sentenceUrl)"
                        >播放</el-button
                      >
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 成语 -->
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    :label="partOfSpeechLabelFormatter(item2.languageType, 4)"
                    prop="idiom"
                  >
                    <el-input
                      v-model="item2.idiom"
                      placeholder="请输入"
                      type="textarea"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    :label="partOfSpeechLabelFormatter(item2.languageType, 5)"
                    prop="idiomUrl"
                  >
                    <el-upload
                      :action="uploadAudioResults.uploadUrl"
                      ref="uploadAudioResultsRef"
                      :data="{
                        path: '',
                        filenamed: '',
                      }"
                      :headers="uploadAudioResults.headerObj"
                      :on-progress="importChineseWordExcelProgress"
                      :on-success="uploadAudioResultsSuccess"
                      :on-error="importChineseWordExcelError"
                      :on-exceed="uploadAudioResultsExceed"
                      :before-upload="uploadAudioResultsBeforeUpload"
                      :limit="1"
                      :on-change="onChangeLoad"
                      :auto-upload="false"
                    >
                      <el-button slot="trigger" size="small" type="primary"
                        >上传</el-button
                      >
                      <el-button
                        size="small"
                        type="primary"
                        style="margin-left: 10px"
                        v-if="item2.idiomUrl"
                        @click="downLoadAudio(item2.idiomUrl)"
                        >下载</el-button
                      >
                      <el-button
                        size="small"
                        type="primary"
                        v-if="item2.idiomUrl"
                        @click="playAudio(item2.idiomUrl)"
                        >播放</el-button
                      >
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 诗句 -->
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    :label="partOfSpeechLabelFormatter(item2.languageType, 6)"
                    prop="classicPoetry"
                  >
                    <el-input
                      v-model="item2.classicPoetry"
                      placeholder="请输入"
                      type="textarea"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    :label="partOfSpeechLabelFormatter(item2.languageType, 7)"
                    prop="classicPoetryUrl"
                  >
                    <el-upload
                      :action="uploadAudioResults.uploadUrl"
                      ref="uploadAudioResultsRef"
                      :data="{
                        path: '',
                        filenamed: '',
                      }"
                      :headers="uploadAudioResults.headerObj"
                      :on-progress="importChineseWordExcelProgress"
                      :on-success="uploadAudioResultsSuccess"
                      :on-error="importChineseWordExcelError"
                      :on-exceed="uploadAudioResultsExceed"
                      :before-upload="uploadAudioResultsBeforeUpload"
                      :limit="1"
                      :on-change="onChangeLoad"
                      :auto-upload="false"
                    >
                      <el-button slot="trigger" size="small" type="primary"
                        >上传</el-button
                      >
                      <el-button
                        size="small"
                        type="primary"
                        style="margin-left: 10px"
                        v-if="item2.classicPoetryUrl"
                        @click="downLoadAudio(item2.classicPoetryUrl)"
                        >下载</el-button
                      >
                      <el-button
                        size="small"
                        type="primary"
                        v-if="item2.classicPoetryUrl"
                        @click="playAudio(item2.classicPoetryUrl)"
                        >播放</el-button
                      >
                    </el-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
        </el-tab-pane>
      </el-tabs>
      <el-row type="flex" justify="center" style="margin-top: 20px">
        <el-col :span="4">
          <el-button type="primary" style="width: 100%" @click="save"
            >保存</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 批量导入汉字的Dialog -->
    <el-dialog
      title="批量导入数据"
      :visible.sync="importChineseWordExcelDialogVisible"
      width="30%"
      @close="importChineseWordExcelDialogClose"
    >
      <el-form
        :model="queryForm"
        label-width="100px"
        ref="importChineseWordInfoFormRef"
        :rules="importChineseWordInfoRules"
        style="width: 100%"
      >
        <el-form-item label="组别" prop="cLevel">
          <el-select v-model="queryForm.cLevel">
            <el-option
              v-for="item in p_c_level"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属产品" prop="productType">
          <el-select v-model="queryForm.productType">
            <el-option
              v-for="item in dict_productType"
              :key="item.dictCode"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="naming-convention">
        音频文件命名规范请参考
        <span class="redcolor-class" @click="openNamingConventionClicked"
          >英文拼词字库音频文件命名规范</span
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-upload
          :action="importChineseWordExcel.uploadUrl"
          ref="importChineseWordExcelRef"
          :data="{
            cLevel: this.queryForm.cLevel,
            productType: this.queryForm.productType,
          }"
          :headers="importChineseWordExcel.headerObj"
          :on-progress="importChineseWordExcelProgress"
          :on-success="importChineseWordExcelSuccess"
          :on-error="importChineseWordExcelError"
          :on-exceed="importChineseWordExcelExceed"
          :on-change="onChangeLoad"
          :limit="1"
          :auto-upload="false"
        >
          <el-button slot="trigger" size="small" type="primary"
            >选取文件</el-button
          >
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="small"
            @click="downloadChineseWordExcelTemplate"
            >下载模版</el-button
          >
          <el-button
            style="margin-left: 10px"
            size="small"
            type="success"
            @click="uploadChineseWordExcel()"
            >上传词库信息</el-button
          >
        </el-upload>
      </span>
    </el-dialog>
    <!-- 选择添加词性类型的Dialog -->
    <el-dialog
      title="新增词性"
      :visible.sync="addPartOfSpeechDialog"
      width="30%"
    >
      <div>
        要添加的词性:
        <el-select placeholder="请选择" v-model="addPartOfSpeechType">
          <el-option
            v-for="item in addPartOfSpeechOption"
            :key="item.dictCode"
            :label="item.dictLabel"
            :value="item.dictValue"
          ></el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addPartOfSpeechDialog = false">取 消</el-button>
        <el-button type="primary" @click="addPartOfSpeech">确 定</el-button>
      </span>
    </el-dialog>
    <audio ref="audioPlayer" @ended="overAudio"></audio>
  </div>
</template>

<script>
import { getCWordList, deleteCWord, addCWord, getCWordInfoGroupList } from '@/http/api'
export default {
  data () {
    return {
      AudioIsPlay: false,
      AudioIsPlayImgUrl: 'https://cdn.spbcn.org/spbcnappneed/voice_small_off.png',
      importChineseWordExcel: {
        uploadUrl: this.$env.baseIP + 'lexicon/importCWorldExcel',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      // 上传音频
      uploadAudioResults: {
        uploadUrl: this.$env.baseIP + '/app/club/uploadFile',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      isUploadFile: false, // 是否已选择上传文件
      importChineseWordExcelDialogVisible: false, // 批量导入弹窗
      editChineseCharactersForm: {
        ctopicList: [],
        speechVo: [
          {
            languageType: 0
          },
          {
            languageType: 1
          }
        ]
      }, // 被选中修改内容
      editChineseCharactersDialogVisible: false,
      total: 0,
      dataList: [],
      importChineseWordInfoRules: {
        cLevel: [{ required: true, message: '请选择', trigger: 'change' }],
        productType: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      dict_productType: this.$userInfo.getDataList(this, 'p_word_product_type').then(value => { this.dict_productType = value }),
      p_c_level: this.$userInfo.getDataList(this, 'p_c_level').then(value => { this.p_c_level = value }),
      dict_group: this.$userInfo.dict_group(),
      dict_topic: this.$userInfo.getDataList(this, 'c_topic').then(value => { this.dict_topic = value }),
      dict_type: this.$userInfo.dict_c_type(),
      dict_e_wordNature: this.$userInfo.getDataList(this, 'e_nature').then(value => { this.dict_e_wordNature = value }),
      dict_c_wordNature: this.$userInfo.getDataList(this, 'c_nature').then(value => { this.dict_c_wordNature = value }),
      queryForm: {
        cLevel: '',
        topic: '',
        cWord: '',
        pageNum: 1,
        pageSize: 10
      },
      // uploadImgUrl: 'spbcnappneed/chineseWord/'
      uploadImgUrl: 'test/', // 更正式是调回
      addPartOfSpeechOption: [],
      addPartOfSpeechDialog: false,
      addPartOfSpeechType: '',
      addPartOfSpeechLanguageType: '',
      props: {},
      options: []
    }
  },
  created () {
    if (this.$route.query.status) {
      this.queryForm.cLevel = this.$route.query.status
    }
    this.getChineseWordList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getChineseWordList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getChineseWordList()
    },
    handleUploadSimpleImgSuccess (res, file) {
      this.editChineseCharactersForm.simpleUrl = res.data.picUrl
    },
    handleUploadSealScriptImgSuccess (res, file) {
      this.editChineseCharactersForm.sealScript = res.data.picUrl
    },
    handleUploadOfficialScriptImgSuccess (res, file) {
      this.editChineseCharactersForm.officialScript = res.data.picUrl
    },
    handleUploadRegularScriptImgSuccess (res, file) {
      this.editChineseCharactersForm.regularScript = res.data.picUrl
    },
    handleUploadWordUrlImgSuccess (res, file) {
      this.editChineseCharactersForm.wordUrl = res.data.picUrl
    },
    beforeImgUpload (file) {
      const isJPGPNG = (file.type === 'image/jpeg' || file.type === 'image/png')
      const isLt2M = file.size / 1024 / 200

      if (!isJPGPNG) {
        this.$message.error('上传图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 200KB!')
      }
      return isJPGPNG && isLt2M
    },
    // 获取字库列表
    getChineseWordList (type) {
      if (type && type === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 20
      }
      getCWordList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 重置搜索内容
    reset () {
      this.queryForm.pageNum = 1
      this.queryForm.pageSize = 20
      this.$refs.queryFormRef.resetFields()
      this.getChineseWordList('search')
    },
    // 修改点击事件
    editDistrictMessage (row) {
      console.log('row', row)
      getCWordInfoGroupList({ id: row.id }).then((res) => {
        this.editChineseCharactersForm = res.data
        if (res.data.ctopic) {
          this.editChineseCharactersForm.ctopicList = res.data.ctopic.split(',')
        }
        this.editChineseCharactersDialogVisible = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 修改弹窗关闭事件
    editChineseCharactersDialogClosed () {
      this.editChineseCharactersForm = {
        speechVo: [
          {
            languageType: 0
          },
          {
            languageType: 1
          }
        ]
      }
      this.overAudio()
    },
    // 删除字库信息点击事件
    deleteDialog (dataId) {
      this.$confirm('是否将该字信息删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 确认删除
        this.confirmDelectCateInfo(dataId)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 删除消息确定事件
    async confirmDelectCateInfo (dataId) {
      console.log('delect', dataId)
      var formData = {}
      formData.id = dataId
      deleteCWord(formData).then((res) => {
        this.getChineseWordList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
      // var formData = {}
      // formData.id = dataId
      // formData.flag = 'delete'
      // const { data: res } = await this.$http.post('/erp/match/eiditDistNotice', formData)
      // console.log('confirmDelectCateInfo', res)
      // if (res.code !== 0) {
      //   return this.$message.error(res.message)
      // }
    },
    // 下载字库模板
    async downloadChineseWordExcelTemplate () {
      var downLoadUrl = this.$env.baseIP + 'lexicon/importTheLexiconTemplate'
      console.log('downLoadUrl', downLoadUrl)
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', {})
    },
    // 监听上传文件变化
    onChangeLoad (files, fileList) {
      if (fileList.length > 0) {
        this.isUploadFile = true
      }
    },
    // 监听上传文件弹窗关闭事件
    importChineseWordExcelDialogClose () {
      this.isUploadFile = false
    },
    // 上传前判断文件是否为空
    uploadChineseWordExcel () {
      if (this.isUploadFile === true) {
        this.$refs.importChineseWordExcelRef.submit()
      } else {
        this.$message.error('请先选择要上传的文件')
      }
    },
    // 上传文件超时
    importChineseWordExcelExceed (files, fileList) {
      console.log('fileList', files, fileList)
      this.$set(fileList[0], 'raw', files[0])
      this.$set(fileList[0], 'name', files[0].name)
      this.$refs.importChineseWordExcelRef.clearFiles()// 清除文件
      this.$refs.importChineseWordExcelRef.handleStart(files[0])// 选择文件后的赋值方法
    },
    // 上传文件加载中
    importChineseWordExcelProgress () {
    },
    // 上传文件成功回调
    importChineseWordExcelSuccess (res) {
      console.log('importChineseWordExcelSuccess', res)
      this.$curUtils.closeLoading(this)
      this.isUploadFile = false
      if (res.code !== 200) {
        this.$refs.importChineseWordExcelRef.clearFiles()// 清除文件
        return this.$alert('请修改后再上传，失败原因:' + res.msg, '上传失败', {
          confirmButtonText: '确定',
          type: 'warning',
          dangerouslyUseHTMLString: true
        })
      }
      this.$message.success('上传字库内容成功')
      this.getChineseWordList()
      this.$refs.importChineseWordExcelRef.clearFiles()// 清除文件
      this.importChineseWordExcelDialogVisible = false
    },
    // 上传文件失败回调
    importChineseWordExcelError (error) {
      console.log('importChineseWordExcelError', error)
      this.$curUtils.closeLoading(this)
      this.$message.error('上次字库文件失败')
    },
    // 下载字库列表文件
    async downloadInfoList () {
      this.$message.error('接口未实现')
      // const { data: res } = await this.$http.get('/word/downloadChineseWordExcelTemplate')
      // console.log('downloadInfoList', res)
      // if (res.code !== 0) {
      //   return this.$message.error(res.message)
      // }
      // var url = res.data
      // var link = document.createElement('a')
      // try {
      //   link.href = url
      // } catch (error) {
      //   link.href = window.URL.createObjectURL(url)
      // }
      // link.click()
    },
    playAudio (url) {
      this.AudioIsPlay = true
      this.AudioIsPlayImgUrl = 'https://cdn.spbcn.org/spbcnappneed/voice_bigger.png'
      this.$refs.audioPlayer.src = url
      this.$refs.audioPlayer.play()
    },
    downLoadAudio (url) {
      window.open(url)
    },
    overAudio () {
      this.$refs.audioPlayer.pause()
      this.AudioIsPlay = false
      this.AudioIsPlayImgUrl = 'https://cdn.spbcn.org/spbcnappneed/voice_small_off.png'
    },
    // 音频文件上传成功
    uploadAudioResultsSuccess (res) {
      this.$curUtils.closeLoading(this)
      this.isUploadFile = false
      if (res.code !== 0) {
        return this.$alert('请修改后再上传，失败原因:' + res.message, '上传失败', {
          confirmButtonText: '确定',
          type: 'warning',
          dangerouslyUseHTMLString: true
        })
      }
      this.$message.success('上传成功')
      this.$refs.uploadAudioResultsRef.clearFiles()// 清除文件
    },
    // 音频文件上传超时
    uploadAudioResultsExceed (files, fileList) {
      console.log('fileList', files, fileList)
      this.$set(fileList[0], 'raw', files[0])
      this.$set(fileList[0], 'name', files[0].name)
      this.$refs.uploadAudioResultsRef.clearFiles()// 清除文件
      this.$refs.uploadAudioResultsRef.handleStart(files[0])// 选择文件后的赋值方法
    },
    ctopicListClicked (val) {
      console.log('val', val)
      this.$forceUpdate()
    },
    uploadAudioResultsBeforeUpload () {
      console.log('isReset', this.isReset)
      return new Promise((resolve, reject) => {
        if (this.matchInfo.teamMatchInfo.length > 0) {
          this.$confirm('确认替换当前音频文件么?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            resolve()
          }).catch(() => {
            this.isUploadFile = false
            reject(new Error('error'))
          })
        } else {
          resolve()
        }
      })
    },
    typeFormatter (row, col, value) {
      console.log('value', value)
      var temp = '未设置'
      this.dict_type.forEach(element => {
        if (element.dictValue + '' === value + '') {
          temp = element.dictLabel
        }
      })
      return temp
    },
    clickLanguage () { },
    labelFormatter (data) {
      switch (data) {
        case 0:
          return '英文词性'
        case 1:
          return '中文词性'
        default:
          return '未知词性'
      }
    },
    partOfSpeechLabelFormatter (type, flag) {
      switch (flag) {
        case 0: // 意思
          return type === 0 ? 'meaning:' : '意思:'
        case 1: // 意思音频
          return type === 0 ? 'Audio:' : '意思音频:'
        case 2: // 例句
          return type === 0 ? 'sentence:' : '例句:'
        case 3: // 例句音频
          return type === 0 ? 'Audio:' : '例句音频:'
        case 4: // 成语
          return type === 0 ? 'idiom:' : '成语:'
        case 5: // 成语音频
          return type === 0 ? 'Audio:' : '成语音频:'
        case 6: // 诗句
          return type === 0 ? 'classicPoetry:' : '诗句:'
        case 7: // 诗句音频
          return type === 0 ? 'Audio:' : '诗句音频:'
        default:
          return '未设置'
      }
    },
    async save () {
      this.editChineseCharactersForm.ctopic = this.editChineseCharactersForm.ctopicList.toString()
      addCWord(this.editChineseCharactersForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
      // const { data: res } = await this.$http.post('/word/addChineseWord', this.editChineseCharactersForm)
      // console.log(res)
      // if (res.code !== 0) {
      //   return this.$message.error(res.message)
      // }
      this.$message.success('保存成功')
      this.editChineseCharactersDialogVisible = false
      this.getChineseWordList()
    },
    showAddPartOfSpeechDialog (languageType) {
      this.addPartOfSpeechLanguageType = languageType
      switch (languageType) {
        case 0:
          this.addPartOfSpeechOption = this.dict_e_wordNature
          this.addPartOfSpeechDialog = true
          break
        case 1:
          this.addPartOfSpeechOption = this.dict_c_wordNature
          this.addPartOfSpeechDialog = true
          break
        default:
          this.$message.warning('未知的languageType')
      }
    },
    addPartOfSpeech () {
      console.log()
      if (this.editChineseCharactersForm.speechVo[this.addPartOfSpeechLanguageType].part === undefined) {
        this.editChineseCharactersForm.speechVo[this.addPartOfSpeechLanguageType].part = []
      }
      this.editChineseCharactersForm.speechVo[this.addPartOfSpeechLanguageType].part.push({
        languageType: this.addPartOfSpeechLanguageType,
        partOfSpeech: this.addPartOfSpeechType
      })
      this.addPartOfSpeechDialog = false
    },
    wordNatureFormatter (data) {
      var temp = '未定义'
      if (data.languageType === 0) { // 英文词性
        for (const key in this.dict_e_wordNature) {
          const element = this.dict_e_wordNature[key]
          if (element.dictValue + '' === data.partOfSpeech) {
            temp = element.dictLabel
          }
        }
      } else if (data.languageType === 1) { // 中文词性
        for (const key in this.dict_c_wordNature) {
          const element = this.dict_c_wordNature[key]
          if (element.dictValue + '' === data.partOfSpeech) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    // 在线查看英文命名规范
    openNamingConventionClicked () {
      window.open('https://cdn.spbcn.org/chineseWord/erp/file/fileNamingConvention.pdf', '_blank')
    },
    deletePartOfSpeech (data, index) {
      data.splice(index, 1)
    },
    cGroupFormatter (data) {
      var temp = '未设置'
      for (const key in this.p_c_level) {
        if (Object.hasOwnProperty.call(this.p_c_level, key)) {
          const element = this.p_c_level[key]
          if (element.dictValue === data.clevel + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    ctopicFormatter (data) {
      console.log('data', data)
      if (data.ctopic) {
        var ctopicList = data.ctopic.split(',')
        var temp = ''
        for (const key in this.dict_topic) {
          if (Object.hasOwnProperty.call(this.dict_topic, key)) {
            const element = this.dict_topic[key]
            for (let index = 0; index < ctopicList.length; index++) {
              const childElement = ctopicList[index]
              if (element.dictValue === childElement + '') {
                temp = temp + (temp === '' ? '' : ',') + element.dictLabel
              }
            }
          }
        }
        return temp
      }
    },
    productTypeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_productType) {
        if (Object.hasOwnProperty.call(this.dict_productType, key)) {
          const element = this.dict_productType[key]
          if (element.dictValue === data.productType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>

<style lang="less" scoped>
.title-class {
  display: -moz-inline-box;
  display: inline-block;
  width: 120px;
}
.naming-convention {
  font-size: 12px;
}
.redcolor-class {
  color: red;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}
.voiceStrClass {
  font-size: 16px;
}
.changeVoiceBack {
  display: flex;
  align-items: center;
}
.playVoiceImageClass {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}
.wordImg {
  width: 100px;
  height: 100px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.audio-play {
  border: 1px solid #ebeef5;
  background-color: #f0f8ff;
  border-radius: 4px;
  font-size: 12px;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100px;
  i {
    font-size: 18px;
    cursor: pointer;
  }
}
</style>
